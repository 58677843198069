import { TabsMenu as TabsMenuUI } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./UserTabsMenuItem.styles";
import { UserTabsMenuItemProps as Props } from "./UserTabsMenuItem.types";
import useI18n from "hooks/useI18n";

import ProfileMobileSVG from "../../../../../public/assets/images/TabsMenu/profile-mobile.svg";
import ProfileSVG from "../../../../../public/assets/images/TabsMenu/profile.svg";

const { Item } = TabsMenuUI;

const UserTabsMenuItem: React.FC<Props> = props => {
  const { className, hideTitle, active, withMobileImage } = props;
  const { push } = useRouter();
  const t = useI18n();

  const icon = !withMobileImage ? ProfileSVG : ProfileMobileSVG;

  const onClickHandler = async () => {
    await push("/profile");
  };

  return (
    <Styles className={`UserTabsMenuItem ${className}`} active={active}>
      <Item
        icon={icon}
        title={hideTitle ? undefined : t.navigation.profile}
        onClick={onClickHandler}
      />
    </Styles>
  );
};

UserTabsMenuItem.defaultProps = {
  className: ""
};

export default UserTabsMenuItem;
