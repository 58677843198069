import { useGeo } from "@sushicorp/contexts";
import { useStores } from "@sushicorp/contexts";
import { useCatalogues } from "@sushicorp/contexts";
import { useShoppingCart } from "@sushicorp/contexts";
import { events } from "artisn/analytics";
import { createShoppingCart, findProduct } from "artisn/shopping-cart";
import { addProduct, removeProduct } from "artisn/shopping-cart";
import { CartProduct } from "artisn/types";
import { useEffect, useState } from "react";

import { UseAddToCartWishlistProps } from "./AddToWishlistButton.types";
import CONSTANTS from "config/constants";
import useAuth from "contexts/auth/auth.context.hooks";
import useShippingCost from "hooks/useShippingCost";

const { CONTENT_TYPE, ACCOUNT_ID } = CONSTANTS.ARTISN;
const { logAddProductToWishlist } = events.shoppingCart;
const { logRemoveProductFromWishlist } = events.shoppingCart;

export const useAddToWishlist = (props: UseAddToCartWishlistProps) => {
  const { product, onFinish, config } = props;
  const { selectedStore } = useStores();
  const { shoppingCartName } = config;
  const { selectedCatalogueId } = useCatalogues();
  const shippingCost = useShippingCost();
  const { selectedCoordinates } = useGeo();
  const { isAnonymous = false, uid } = useAuth();
  const { wishlist } = useShoppingCart();
  const { productId } = product ?? {};
  const [inCart, setInCart] = useState(false);

  const { lat, lng } = selectedCoordinates ?? {};

  const onClick = async () => {
    if (!product) return;
    if (!uid) return;
    if (!selectedStore) return;

    const { id: cartId, name: cartName } = wishlist ?? {};

    if (!wishlist) {
      await createShoppingCart(
        {
          anonymous: isAnonymous,
          accountId: ACCOUNT_ID,
          customerId: uid
        },
        {
          channelId: +selectedCatalogueId,
          shippingCost,
          latitude: lat ?? 0,
          longitude: lng ?? 0,
          name: shoppingCartName
        }
      );
    }

    if (inCart) {
      removeProduct(product, {
        shoppingCartName,
        anonymous: isAnonymous,
        accountId: ACCOUNT_ID,
        customerId: uid,
        store: selectedStore
      });
      setInCart(false);

      if (cartId && product) {
        logRemoveProductFromWishlist({
          cartId,
          product: product as CartProduct,
          contentType: CONTENT_TYPE
        });
      }

      onFinish?.();
      return;
    }

    addProduct(product, {
      amount: 1,
      store: selectedStore,
      shoppingCartName,
      anonymous: isAnonymous,
      customerId: uid,
      accountId: ACCOUNT_ID
    });
    setInCart(true);

    if (cartId && cartName) {
      logAddProductToWishlist({
        cartId,
        cartName,
        product: product as CartProduct,
        contentType: CONTENT_TYPE
      });
    }

    onFinish?.();
  };

  useEffect(() => {
    if (!uid) return;
    if (typeof productId === "undefined") return;
    (async () => {
      const inCart = !!(await findProduct({
        productId: productId,
        shoppingCartName,
        accountId: ACCOUNT_ID,
        customerId: uid
      }));
      setInCart(inCart);
    })();
  }, [productId, shoppingCartName, uid, wishlist]);

  return { onClick, inCart };
};
