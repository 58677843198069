import { useCatalogues } from "@sushicorp/contexts";
import { events } from "artisn/analytics";
import { ButtonGroup } from "artisn-ui-react";
import { useRouter } from "next/router";
import React from "react";

import Styles from "./ChooseWorkflowSwitch.styles";
import { ChooseWorkflowSwitchProps as Props } from "./ChooseWorkflowSwitch.types";
import { InfoShoppingCartModal } from "../InfoShoppingCartModal/InfoShoppingCartModal";
import CONSTANTS from "config/constants";
import { useDeleteShoppingCart } from "hooks/useDeleteShoppingCart";
import { Workflow } from "types/workflow.types";

import DeliverySVG from "../../../../public/assets/images/delivery.svg";
import PickupSVG from "../../../../public/assets/images/pickup.svg";

const { Button } = ButtonGroup;
const { logUpdateWorkflow } = events.workflow;
const { WITH_MULTIPLE_PURCHASE_METHODS } = CONSTANTS.FEATURE_FLAGS;
const { CATALOGUES } = CONSTANTS.ARTISN;
const [DELIVERY, PICK_UP] = CATALOGUES;

const ChooseWorkflowSwitch: React.FC<Props> = props => {
  const { className, onChange } = props;
  const { selectedCatalogue, setSelectedCatalogueId } = useCatalogues();
  const { name: catalogueName } = selectedCatalogue;
  const { isEmptyShoppingCart, emptyCartHandler } = useDeleteShoppingCart();
  const { asPath } = useRouter();

  const chooseWorkflowHandler = (catalogueId: string) => {
    const catalogue = CATALOGUES.find(item => item.catalogueId === catalogueId);

    if (!catalogue) {
      throw new Error(
        `"${catalogueId}" does not correspond to a valid catalogue ID`
      );
    }

    logUpdateWorkflow({
      previousWorkflow: catalogueName as Workflow,
      nextWorkflow: catalogue.name as Workflow
    });
    setSelectedCatalogueId(catalogueId);
    onChange?.();
  };

  const changeHandler = async (e: React.ChangeEvent<any>) => {
    const catalogueId = e.target.value;
    if (!isEmptyShoppingCart && !asPath.includes("checkout")) {
      try {
        const isConfirmed = await InfoShoppingCartModal({});
        if (isConfirmed) {
          emptyCartHandler();
          chooseWorkflowHandler(catalogueId);
          return;
        }
        throw new Error("The promise in the modal is rejected");
      } catch (e) {
        console.error(e.message);
      }
      return;
    }
    chooseWorkflowHandler(catalogueId);
  };

  if (!WITH_MULTIPLE_PURCHASE_METHODS) return null;

  return (
    <Styles
      className={`ChooseWorkflowSwitch ${className}`}
      defaultActive={catalogueName}
      onChange={changeHandler}
    >
      <Button
        className="ChooseWorkflowSwitch__button"
        id={DELIVERY.name}
        value={DELIVERY.catalogueId}
      >
        <DeliverySVG viewBox="0 0 16 16" />
        <p className="ChooseWorkflowSwitch__button__text">Domicilio</p>
      </Button>
      <Button
        className="ChooseWorkflowSwitch__button"
        id={PICK_UP.name}
        value={PICK_UP.catalogueId}
      >
        <PickupSVG />
        <p className="ChooseWorkflowSwitch__button__text">Pickup</p>
      </Button>
    </Styles>
  );
};

ChooseWorkflowSwitch.defaultProps = {
  className: ""
};

export default ChooseWorkflowSwitch;
