import styled from "styled-components";

import { LogoStyledProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { desktop } = BREAKPOINTS;

const LogoStyled = styled.div<Props>`
  .Logo {
    &__mobile {
      @media (min-width: ${desktop + 1}px) {
        display: none;
      }
    }

    &__desktop {
      @media (max-width: ${desktop}px) {
        display: none;
      }
    }
  }
`;

export default LogoStyled;
