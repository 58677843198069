import Link from "next/link";
import React from "react";

import Styles from "./Logo.styles";
import { LogoProps as Props } from "./Logo.types";
import CONSTANTS from "config/constants";

import LogoMobileSVG from "../../../../public/assets/images/logo-mobile.svg";
import LogoSVG from "../../../../public/assets/images/logo.svg";

const { TITLE } = CONSTANTS;

const Logo: React.FC<Props> = props => {
  const { className, onClick } = props;
  const label = `${TITLE} logo`;

  return (
    <Styles className={`Logo ${className}`}>
      {onClick ? (
        <div onClick={onClick} aria-label={label}>
          <LogoMobileSVG className="Logo__mobile" />
          <LogoSVG className="Logo__desktop" />
        </div>
      ) : (
        <Link href="/" passHref aria-label={label}>
          <a aria-label={label}>
            <LogoMobileSVG className="Logo__mobile" />
            <LogoSVG className="Logo__desktop" />
          </a>
        </Link>
      )}
    </Styles>
  );
};

Logo.defaultProps = {
  className: ""
};

export default Logo;
