import styled from "styled-components";

import { NavbarStyledProps as Props } from "./Navbar.types";
import CONSTANTS from "../../../config/constants";

const { BREAKPOINTS, FEATURE_FLAGS } = CONSTANTS;
const { desktop, tablet } = BREAKPOINTS;
const { WITH_SELECT_ADDRESS_DROPDOWN } = FEATURE_FLAGS;

const NavbarStyled = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  background-color: var(--palette-white);
  height: var(--sizes-navbar-height);
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  align-items: center;
  grid-template-areas: ". content .";

  @media (max-width: ${desktop}px) {
    height: ${props =>
      !props.withBottomResponsiveButtons
        ? "var(--sizes-navbar-height-mobile-without-bottom-buttons)"
        : props.withBottomSearch
        ? "var(--sizes-navbar-height-mobile)"
        : "var(--sizes-navbar-height-mobile-without-search)"};
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .Navbar {
    &__content {
      grid-area: content;
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__greeting-text {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 2rem;
      color: var(--palette-primary);
      padding: 1.6rem 0 1.6rem 2.8rem;
      margin-right: 1.6rem;

      @media (max-width: ${tablet - 1}px) {
        display: none;
      }

      @media (min-width: ${desktop}px) {
        margin-right: 0;
      }
    }

    &__dropdown {
      margin-left: 2.4rem;
      flex-shrink: 0;
      background-color: unset;

      @media (max-width: ${tablet}px) {
        width: 100%;
      }

      @media (max-width: ${desktop}px) {
        margin-left: 0;
        flex-shrink: 1;

        .SelectAddressDropdown {
          &__button-container {
            width: 100%;
          }

          &__button {
            width: 100%;
            box-shadow: unset;
          }

          &__button-text-container {
            display: flex;
            align-items: baseline;
          }
        }
      }

      @media (min-width: ${tablet}px) and (max-width: ${desktop}px) {
        .SelectAddressDropdown {
          &__button-text-container {
            max-width: 40rem;

            p {
              margin-right: 0.4rem;
            }
          }
        }
      }
    }

    &__top {
      flex-wrap: nowrap;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: justify;
      justify-content: space-between;
      display: flex;

      .Navbar {
        &__dropdown {
          &--responsive {
            @media (max-width: ${desktop}px) {
              display: none;
            }
          }
        }
      }

      &--brand {
        @media (max-width: ${desktop}px) {
          flex: 0;
          margin-right: 1rem;
        }
      }

      &--responsive {
        justify-content: space-between;
        display: none;

        &.not-home {
          @media (max-width: ${desktop}px) {
            display: flex;
          }
        }

        &-divider {
          height: 0.1rem;
          background-color: var(--palette-black-s2-l17);
        }

        .Navbar__btn--profile {
          @media (max-width: ${tablet}px) {
            display: none;
          }
        }

        @media (max-width: ${desktop}px) {
          margin-top: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__buttons {
        display: flex;
        align-items: center;
        .Navbar__btn:not(:last-child) {
          margin-left: 1.6rem;
        }
      }

      &__secondary {
        &--hide {
          display: none !important;
        }

        &__button,
        &__button:hover {
          background-color: var(--palette-black-s0-l10) !important;
          border-color: var(--palette-black-s0-l10) !important;
          font-weight: 700;
          font-size: 1.6rem;
          padding: 1.2rem;
          width: 50%;
          margin-right: 0.8rem;

          @media (max-width: ${desktop}px) {
            font-size: 1.2rem;
          }
        }

        &__greeting {
          font-weight: 700;
          font-size: 1.6rem;
          line-height: 2rem;
          color: var(--palette-primary);
          margin-right: 0.8rem;
          width: 70%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
        }
      }

      &__second-row {
        @media (min-width: ${desktop + 1}px) {
          display: none;
        }
      }
    }

    &__title {
      font-size: 1.6rem;
      font-weight: 600;
      color: var(--palette-black-s0-l10);
    }

    &__back {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
    }

    &__bottom {
      display: none;
      flex-wrap: nowrap;
      align-items: center;

      @media (max-width: ${desktop}px) {
        display: flex;
      }
    }

    &__search-bar {
      max-width: 60rem;
      width: 52%;
      margin-left: ${WITH_SELECT_ADDRESS_DROPDOWN ? "2.4rem" : "auto"};
      border-radius: 8px;

      .SearchBarProducts__search-bar {
        max-width: unset;
      }

      &--center {
        margin: 0;
        max-width: 60rem;
        padding: 0 2rem;
        border-radius: 0.8rem;
        overflow: hidden;

        .SearchBar__input {
          max-width: 19.2rem;
        }

        @media (max-width: ${desktop}px) {
          padding: 0;
          max-width: 22rem;

          .SearchBar__input {
            max-width: 15.2rem;
          }
        }
      }

      &--bottom {
        margin: 0;
        padding-top: 0.8rem;
        max-width: none;
      }

      &--responsive {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        margin-top: 1.2rem;

        @media (max-width: ${desktop}px) {
          max-width: 100%;
          margin-bottom: 0.8rem;

          .SearchBar__dropdown {
            width: 100%;
          }
        }
      }
    }

    &__btn {
      visibility: ${props => (props.talkShop ? "hidden" : "visible")};
      font-weight: 700;

      @media (max-width: ${desktop}px) {
        font-size: 1.2rem;
      }

      &:last-child {
        padding-left: 2rem;

        @media (max-width: ${desktop}px) {
          padding-left: 1.2rem;
        }
      }

      &--coupons {
        cursor: pointer;

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &--profile {
        margin-left: auto;
        cursor: pointer;

        & path {
          fill: var(--palette-black-s0-l10);
        }
      }

      &--sign-in {
        font-size: 1.6rem;
        font-weight: 700;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        background-color: transparent;
        border-color: transparent;
        color: var(--palette-black-h60-s25-l02-10);

        &:hover {
          background-color: transparent !important;
          border-color: transparent !important;
          color: var(--palette-black-h60-s25-l02-10) !important;
        }

        .Button__loading__icon {
          path {
            fill: var(--palette-black-h60-s25-l02-10);
          }
        }

        @media (max-width: ${tablet - 1}px) {
          display: ${props =>
            props.withBottomSearch ? "initial" : "none !important"};
        }

        @media (max-width: ${desktop}px) {
          font-size: 1.2rem;
        }
      }

      &--order-now.Button,
      &--order-now.Button:hover {
        font-size: 1.6rem;
        font-weight: 700;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        flex: 0 1 auto;
        background-color: var(--palette-black-s0-l10);
        border: var(--palette-black-s0-l10);

        @media (max-width: ${desktop}px) {
          font-size: 1.2rem;
          width: 100%;
        }
      }

      &--settings {
        @media (max-width: ${desktop}px) {
          margin-left: auto;
        }
      }

      &--cart {
        .Button {
          font-size: 1.6rem;
          font-weight: 700;
          border-radius: 50% !important;
          border-color: transparent !important;

          path {
            fill: var(--palette-black-h60-s25-l02-10) !important;
          }

          .CartButton__badge {
            path {
              fill: var(--palette-white) !important;
            }
          }

          .CartButton__title {
            display: none;
          }

          @media (min-width: ${desktop + 1}px) {
            padding: 0 !important;
            width: 4.8rem;
            height: 4.4rem;

            .Badge__value {
              top: -14px;
              right: -14px;
            }
          }
        }

        @media (max-width: ${desktop}px) {
          .Button {
            width: 4rem;
            height: 4rem;
            padding: 0 !important;
          }
        }

        @media (max-width: ${desktop}px) {
          margin-left: 0.8rem;
        }
      }

      &--search,
      &--search:hover {
        width: 4.8rem;
        height: 4rem;
        padding: 0 !important;
        border-radius: 2.4rem;
        background-color: var(--palette-gray-s0-l97) !important;
        margin-left: 0.8rem;
        border: none !important;
        box-shadow: none !important;

        svg path {
          fill: var(--palette-gray-s0-l50);
        }
      }

      &--hidden {
        visibility: hidden;
      }
    }

    &__search-bar--top,
    &__btn--loyalty,
    &__btn--coupons,
    &__top__buttons {
      margin-bottom: 0.4rem;
      @media (max-width: ${desktop}px) {
        display: none;
      }
    }

    &__order-now-container {
      width: 100%;
    }
  }
`;

export default NavbarStyled;
