import styled from "styled-components";

import { CartButtonStyledProps as Props } from "./CartButton.types";
import CONSTANTS from "config/constants";

const { WITH_WISHLIST } = CONSTANTS.FEATURE_FLAGS;
const { desktop } = CONSTANTS.BREAKPOINTS;

const CartButtonStyled = styled.div<Props>`
  cursor: pointer;

  &&& .CartButton {
    &__title {
      color: inherit;
      font-weight: 700;
      margin-right: 0.4rem;
    }

    &__badge {
      margin-left: 0;

      & .Badge__value {
        background-color: var(--palette-black);
      }
    }

    @media (max-width: ${desktop + 176}px) {
      &__title {
        display: none;
      }

      &__icon {
        margin-left: 0;
      }
    }

    &__button {
      border-radius: 12rem;
      padding: 1.2rem 2.4rem;

      @media (max-width: ${desktop}px) {
        border-radius: 0.8rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }

    &--heart {
      display: none;

      path {
        fill: var(--palette-primary);
      }

      @media (hover: none) {
        display: ${props =>
          props.isProductDetails && WITH_WISHLIST && props.isSignedIn
            ? "block"
            : "none"};
      }
    }

    &--cart {
      display: flex;

      @media (max-width: ${desktop - 1}px) {
        margin-left: 0;
      }
    }
  }
`;

export default CartButtonStyled;
